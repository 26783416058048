/**
 * Checks if the user's device supports hovering and fine pointer input.
 *
 * @returns {boolean} A boolean value indicating whether the user's device supports hover and fine pointer input.
 */
export const windowMatchesHover = () => {
	return window.matchMedia( '(hover: hover) AND (pointer: fine)' ).matches;
};

/**
 * Checks if the user's device is below a certain width.
 *
 * @param {number} width The width to check against.
 * @returns {boolean}
 */
export const isWindowBelowWidth = ( width = 1024 ) => {
	return window.matchMedia( `(max-width: ${width}px)` ).matches;
};

/**
 * Checks if the user's device is above a certain width.
 *
 * @param {number} width The width to check against.
 * @returns {boolean}
 */
export const isWindowAboveWidth = ( width = 1024 ) => {
	return window.matchMedia( `(min-width: ${width}px)` ).matches;
};
